import {
    h,
    resolveComponent
} from "vue";
import main from "./middleware/middleware";

export default [
    {
        path: '',
        name: 'home',
        component: () => import("../views/Home.vue"),
        meta: {
            middleware: [main],
            sidebarLinks: [
                {
                    page: "home",
                    id: "header",
                    title: "Header",

                    translate: true
                },
                {
                    page: "home",
                    id: "about",
                    title: "Who are we?",
                    translate: true
                },
                {
                    page: "home",
                    id: "products",
                    title: "Our Products",
                    translate: true
                },
                {
                    page: "home",
                    id: "statistics",
                    title: "Statistics",
                    translate: true
                },
                {
                    page: "home",
                    id: "partnerships",
                    title: "Partnerships",
                    translate: true
                },
                {
                    page: "home",
                    id: "clients",
                    title: "Our Clients",
                    translate: true
                },
            ]
        },
    },
    {
        path: 'solar-energy-solutions',
        name: 'solar-energy-solutions',
        component: () => import("../views/solar-energy/Solutions.vue"),
        meta: {
            middleware: [main],
            sidebarLinks: [
                {
                    page: "solar-energy-solutions",
                    id: "header",
                    title: "Header",
                    translate: true
                },
            ]
        },
    },
    {
        path: 'solar-energy-services',
        name: 'solar-energy-services',
        component: () => import("../views/solar-energy/Services.vue"),
        meta: {
            middleware: [main],
            sidebarLinks: [
                {
                    page: "solar-energy-services",
                    id: "header",
                    title: "Header",
                    translate: true
                },
            ]
        },
    },

    {
        path: 'electricity-solutions',
        name: 'electricity-solutions',
        component: () => import("../views/electricity/Solutions.vue"),
        meta: {
            middleware: [main],
            sidebarLinks: [
                {
                    page: "electricity-solutions",
                    id: "header",
                    title: "Header",
                    translate: true
                },
            ]
        },
    },
    {
        path: 'electricity-services',
        name: 'electricity-services',
        component: () => import("../views/electricity/Services.vue"),
        meta: {
            middleware: [main],
            sidebarLinks: [
                {
                    page: "electricity-services",
                    id: "header",
                    title: "Header",
                    translate: true
                },
            ]
        },
    },


    {
        path: 'blog',
        name: 'blog',
        component: () => import("../views/blog/Index.vue"),
        meta: {
            middleware: [main],
            sidebarLinks: [
                {
                    page: "blog",
                    id: "news",
                    title: "News",
                    translate: true
                },
                {
                    page: "blog",
                    id: "articles",
                    title: "Articles",
                    translate: true
                },
                {
                    page: "blog",
                    id: "projects",
                    title: "Projects",
                    translate: true
                },

            ]
        },
    },
    {
        path: 'news/:slug',
        name: 'news-details',
        component: () => import("../views/blog/News/Details.vue"),
        meta: {
            middleware: [main],
            sidebarLinks: [


            ]
        },
    },
    {
        path: 'articles/:slug',
        name: 'articles-details',
        component: () => import("../views/blog/Articles/Details.vue"),
        meta: {
            middleware: [main],
            sidebarLinks: [


            ]
        },
    },
    {
        path: 'projects/:slug',
        name: 'projects-details',
        component: () => import("../views/blog/Projects/Details.vue"),
        meta: {
            middleware: [main],
            sidebarLinks: [
            ]
        },
    },


    {
        path: 'products/:slug',
        name: 'products',
        component: () => import("../views/products/Index.vue"),
        meta: {
            middleware: [main],
            sidebarLinks: [
                {
                    page: "products",
                    id: "categories",
                    title: "Shop by Categories",
                    translate: true
                },
                {
                    page: "products",
                    id: "agencies",
                    title: "Shop by Agencies",
                    translate: true
                },
            ]
        },
    },
    {
        path: 'product/details/:id',
        name: 'product-details',
        component: () => import("../views/products/Details.vue"),
        meta: {
            middleware: [main],
            sidebarLinks: [
            ]
        },
    },

    {
        path: 'partnerships/details/:id',
        name: 'partnerships-details',
        component: () => import("../views/PartnershipDetails.vue"),
        meta: {
            middleware: [main],
            sidebarLinks: [

            ]
        },
    },
    {
        path: 'contact-us',
        name: 'contact-us',
        component: () => import("../views/ContactUs.vue"),
        meta: {
            middleware: [main],
        },
    },
];