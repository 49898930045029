import {
  createRouter,
  createWebHistory
} from 'vue-router'
import i18n from "../libs/i18n";
import pages from "./pages";
import main from "./middleware/middleware";
const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        top: 100,
        behavior: "smooth",
      };
    }

    return {
      top: 0
    };
  },
  routes: [{
    path: "/",
    redirect: `/${i18n.global.locale.value}/`,
  },
  {
    path: "/:lang",
    children: [
      ...pages,
      {
        path: ":pathMatch(.*)*",
        redirect: `/${i18n.global.locale.value}/`,
      },
    ],
  },
  ]
})

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({
      ...context,
      next: nextMiddleware
    });
  };
}

router.beforeEach((to, from, next) => {
  const langs = ["ar", "en"];
  if (
    to.name == "solar-energy-solutions" ||
    to.name == "solar-energy-services" ||
    to.name == "electricity-solutions" ||
    to.name == "electricity-services" ||
    to.name == "home"
  ) {
    // if route does not have hash then redirect to the first section
    if (!to.hash) {
      return next({
        name: to.name,
        params: {
          lang: i18n.global.locale.value
        },
        hash: "#header"
      });
    }
  }
  if (langs.includes(to.params.lang)) {
    i18n.global.locale.value = to.params.lang;
    const getHTMLTag = document.documentElement;
    getHTMLTag.setAttribute("lang", to.params.lang);
    getHTMLTag.setAttribute("dir", to.params.lang == "ar" ? "rtl" : "ltr");

    if (to.meta.middleware) {
      const middleware = Array.isArray(to.meta.middleware) ?
        to.meta.middleware : [to.meta.middleware];

      const context = {
        from,
        next,
        router,
        to,
      };
      const nextMiddleware = nextFactory(context, middleware, 1);

      return middleware[0]({
        ...context,
        next: nextMiddleware
      });
    }


    return next({
      name: 'home',
      params: {
        lang: i18n.global.locale.value
      }
    });


  } else {

    if (to.name) {
      next({
        name: to.name,
        params: {
          lang: i18n.global.locale.value
        }
      });
    } else next({
      name: "home",
      params: {
        lang: i18n.global.locale.value
      }
    });
    return next();
  }


});
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    // appLoading.style.display = 'none';
  }
});

router.resolve({
  name: "home",
  params: {
    lang: i18n.global.locale.value
  },
}).href;
export default router