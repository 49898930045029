<script setup >
import { onMounted, ref } from "vue";
import logoAnimation from "@/assets/images/logo-animation.gif";
import lightLogoAnimation from "@/assets/images/light-logo-animation.gif";

const flTheme = localStorage.getItem("fl_theme");

onMounted(() => {});
</script>
<template>
  <div class="loading" id="loading">
    <div class="loading-icon">
     <img v-if="flTheme == 'dark'"  :src="lightLogoAnimation" alt="lightLogoAnimation" />
     <img v-else :src="logoAnimation" alt="logoAnimation" />
      <!-- <app-logo v-if="flTheme == 'dark'" />
      <app-logo-light v-else /> -->
    </div>
  </div>
</template>
