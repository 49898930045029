<script setup>
import { onMounted, ref, watch, getCurrentInstance } from "vue";
import Navbar from "@/components/layouts/Navbar.vue";
import Footer from "@/components/layouts/Footer.vue";
import Loading from "@/components/Loading.vue";
import { useUtilityStore } from "@/stores/utility";
import { useAppsStore } from "@/stores/app";
import { useRouter, useRoute } from "vue-router";
import VueElementLoading from "vue-element-loading";

const flTheme = localStorage.getItem("fl_theme");

const storeUtility = useUtilityStore();
const storeApp = useAppsStore();
const LoadingInt = ref(false);
const router = useRouter();
const route = useRoute();
const sidebarLinks = ref([]);
const showSidebarLinks = ref(true);
const checkHashDataMenuanchor = (hash, id) => {
  var element = document.getElementById(id + "-link");
  if (hash && hash.includes(id) && element) {
    element?.classList.add("active");
    return "active";
  } else {
    element?.classList.remove("active");
    return "";
  }
};

watch(
  () => [route.meta.sidebarLinks, storeUtility.getPageContents],
  () => {
    if (
      route.name == "solar-energy-solutions" ||
      route.name == "solar-energy-services" ||
      route.name == "electricity-solutions" ||
      route.name == "electricity-services"
    ) {
      setTimeout(() => {
        sidebarLinks.value = route.meta.sidebarLinks;
      }, 100);
    } else {
      sidebarLinks.value = route.meta.sidebarLinks;
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

onMounted(() => {
  if (!localStorage.getItem("fl_theme")) {
    localStorage.setItem("fl_theme", "dark");
  }

  const currentTheme = localStorage.getItem("fl_theme")
    ? localStorage.getItem("fl_theme")
    : "dark";

  if (currentTheme) {
    document.documentElement.setAttribute("data-theme", currentTheme);
  }

  storeUtility.loadAppData().then(() => {
    setTimeout(() => {
      LoadingInt.value = true;
    }, 1000);
  });
});
</script>

<template>
  <Loading v-show="!LoadingInt" />
  <div v-show="LoadingInt">
    <vue-element-loading
      :active="storeApp.getloading"
      spinner="mini-spinner"
      color="#FF0000"
      background-color="rgba(255, 255, 255, 0.3)"
    />
    <div class="app-layout">
      <div class="app-layout-sidbar">
        <div class="app-layout-sidbar-header">
          <router-link
            :to="{ name: 'home', params: { lang: $i18n.locale } }"
            class="brand"
            aria-label="app logo"
          >
            <app-logo v-if="flTheme == 'dark'" />
            <app-logo-light v-else />
          </router-link>
        </div>

        <div class="app-layout-sidbar-content">
          <div class="app-layout-sidbar-content-list" id="menu">
            <div
              class="app-layout-sidbar-content-list-item"
              v-for="(link, index) in sidebarLinks"
              :key="index"
              :data-menuanchor="link.id"
              :id="link.id + '-link'"
              :class="checkHashDataMenuanchor($route.hash, link.id)"
            >
              <a
                :href="`#${link.id}`"
                class="app-layout-sidbar-content-list-item-link"
              >
                {{
                  link.translate ? $t("sidbar." + link.title) : link.title
                }}</a
              >
            </div>
          </div>
        </div>
        <div class="app-layout-sidbar-footer">
          <a
            :href="
              storeUtility.getAppData?.contact_details?.whatsapp
                ? `https://wa.me/${storeUtility.getAppData?.contact_details?.whatsapp}`
                : '#'
            "
            target="_blank"
            aria-label="whatsapp phone"
            ><whatsapp-icon
          /></a>
          <a
            :href="storeUtility.getAppData?.contact_details?.twitter ?? '#'"
            target="_blank"
            aria-label="twitter link"
            ><twitter-icon
          /></a>
          <a
            :href="storeUtility.getAppData?.contact_details?.instagram ?? '#'"
            target="_blank"
            aria-label="instagram link"
            ><instagram-icon
          /></a>
          <a
            :href="storeUtility.getAppData?.contact_details?.facebook ?? '#'"
            target="_blank"
            aria-label="facebook link"
            ><facebook-icon
          /></a>
        </div>
      </div>
      <div class="w-page h-100">
        <Navbar />
        <RouterView />
      </div>
    </div>

    <Footer />
  </div>
</template>