import appLogoIcon from "./app-logo.svg";
import appLogoLightIcon from "./app-logo-light.svg";
import titleBeforeIcon from "./title-before-icon.svg";
import arrowLeftIcon from "./arrow-left.svg";
import XMLID_893_Icon from "./XMLID_893_.svg";
import XMLID_981_Icon from "./XMLID_981_.svg";
import XMLID_2405_Icon from "./XMLID_2405_.svg";
import XMLID_1024_Icon from "./XMLID_1024_.svg";
import arrowRightIcon from "./arrow-right.svg";
import contactUsBgIcon from "./contact-us-bg.svg";




import facebookIcon from "./facebook.svg";
import twitterIcon from "./twitter.svg";
import instagramIcon from "./instagram.svg";
import whatsappIcon from "./whatsapp.svg";
import linkedinIcon from "./linkedin.svg";
import youtubeIcon from "./youtube.svg";
import tiktokIcon from "./tiktok.svg";
import emailIcon from "./email.svg";
import phoneIcon from "./phone.svg";
import arrowBottomIcon from "./arrow-bottom.svg";
import moonIcon from "./moon.svg";
import sunIcon from "./sun.svg";
import chevronRightIcon from "./chevron-right.svg";
import chevronLeftIcon from "./chevron-left.svg";
import menu2RecIcon from "./menu-2-rec.svg";
import crossRecIcon from "./cross-rec.svg";




const svgvendors = (app) => {
    return app
        .component("app-logo", appLogoIcon)
        .component("app-logo-light", appLogoLightIcon)
        .component("title-before-icon", titleBeforeIcon)
        .component("arrow-left-icon", arrowLeftIcon)
        .component("XMLID-893-icon", XMLID_893_Icon)
        .component("XMLID-981-icon", XMLID_981_Icon)
        .component("XMLID-2405-icon", XMLID_2405_Icon)
        .component("XMLID-1024-icon", XMLID_1024_Icon)
        .component("arrow-right-icon", arrowRightIcon)
        .component("facebook-icon", facebookIcon)
        .component("twitter-icon", twitterIcon)
        .component("instagram-icon", instagramIcon)
        .component("contact-us-bg-icon", contactUsBgIcon)


        .component("whatsapp-icon", whatsappIcon)
        .component("linkedin-icon", linkedinIcon)
        .component("youtube-icon", youtubeIcon)
        .component("tiktok-icon", tiktokIcon)
        .component("email-icon", emailIcon)
        .component("phone-icon", phoneIcon)
        .component("arrow-bottom-icon", arrowBottomIcon)
        .component("moon-icon", moonIcon)
        .component("sun-icon", sunIcon)
        .component("chevron-right-icon", chevronRightIcon)
        .component("chevron-left-icon", chevronLeftIcon)
        .component("menu-2-rec-icon", menu2RecIcon)
        .component("cross-rec-icon", crossRecIcon)

        ;
};
export default svgvendors;