import axios from "axios"
import router from "@/router"
import {
    useAppsStore
} from "@/stores/app";
import i18n from '@/libs/i18n';

const baseURL = import.meta.env.VITE_API_URL;
const language = () => {
    const pathLang = window.location.pathname.match(/^\/(\w+)/)?.[1];    
    if (pathLang === 'ar' || pathLang === 'en') {
        return pathLang === 'en' ? 'en' : 'ar';
    }
    return i18n.global.locale.value === 'en' ? 'en' : 'ar';
};

const axiosInstance = app => {
    let notify = app.config.globalProperties.$notificationMessage;

    app.config.globalProperties.$http = axios.create({
        baseURL: baseURL,
        headers: {
            "locale": language(),
            "Content-Type": "application/json;  charset=UTF-8",
        },
    })

    let $http = app.config.globalProperties.$http;

    $http.interceptors.request.use(request => {
        request.headers.locale = language();

        return request
    })


    $http.interceptors.response.use(
        (response) => {
            const appsStore = useAppsStore();

            appsStore.setErrors({});
            return response;
        },
        (error) => {
            switch (error.response.status) {
                case 401:
                    break;

                case 400:
                    notify({
                        title: error.response.data.data.code ? app.config.globalProperties.$t('error_messages.' + error.response.data.data.code) : app.config.globalProperties.$t('error_messages.500'),
                        type: "fail",
                    });
                    break;
                case 403:
                case 404:
                    // router.push({
                    //     name: "not-found",
                    //     params: { lang: i18n.global.locale.value },
                    // })
                    notify({
                        title: app.config.globalProperties.$t('error_messages.404'),
                        type: "fail",
                    });
                    break;
                case 422:
                    const appsStore = useAppsStore();
                    appsStore.setErrors(error.response.data.data);

                    notify({
                        title: app.config.globalProperties.$t('error_messages.422'),
                        type: "fail",
                    });
                    break;
                default:
                    notify({
                        title: app.config.globalProperties.$t('error_messages.500'),
                        type: "fail",
                    });
            }
            //handel errors here
            return Promise.reject(error);
        }
    );
    return app.config.globalProperties.$http;
};
export default axiosInstance;